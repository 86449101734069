import React from "react";

import type { LayoutType } from "../../layout/Layout";
import type { ArticlesFeedProps } from "./components/BaseFeedView";

import { BaseFeedView } from "./components/BaseFeedView";
import { BookmarksFeedView } from "./components/BookmarksFeedView";

export function FeedView({
  feed,
  type
}: {
  feed: ArticlesFeedProps;
  type?: LayoutType;
}) {
  switch (type) {
    case "main":
      return <BaseFeedView type={type} feed={feed} />;
    case "latest":
      return <BaseFeedView type={type} feed={feed} />;
    case "categories":
      return <BaseFeedView type={type} feed={feed} />;
    case "topics":
      return <BaseFeedView type={type} feed={feed} />;
    case "bookmarks":
      return <BookmarksFeedView type={type} feed={feed} />;
    default:
      return <BaseFeedView type={type} feed={feed} />;
  }
}
