import React from "react";
import Head from "next/head";

import type { ArticlesResponse } from "../client";

import { FeedView } from "../views/FeedView";
import { fetchArticles } from "../client";
import { Error404 } from "../lib/errors";
import { UseErrorHandler } from "../hooks/useErrorHandling";
import { setCacheHeaders } from "../helpers/setCacheHeaders";
import { GlobalState } from "../containers/GlobalStateContainer";
import {
  type PagePropsWithGlobalState,
  getUserData,
  getSiteData,
  type CustomPageContext
} from "../server/getData";
import { serializePropsResponse } from "../helpers/serializeResponse";
import { getConfig } from "../config";

export interface MainPageProps extends PagePropsWithGlobalState {
  articles: ArticlesResponse;
}

const Main: React.FC<MainPageProps> = ({
  articles,
  userData,
  siteData,
  errorCode
}) => {
  const { NEXT_PUBLIC_DOMAIN } = getConfig();

  return (
    <>
      <Head>
        <link rel="canonical" href={`https://${NEXT_PUBLIC_DOMAIN}`} />
      </Head>
      <UseErrorHandler errorCode={errorCode}>
        <GlobalState userData={userData} siteData={siteData}>
          <FeedView feed={{ articles: articles }} type={"main"} />
        </GlobalState>
      </UseErrorHandler>
    </>
  );
};

export const getServerSideProps = async ({
  query,
  res,
  req
}: CustomPageContext) => {
  setCacheHeaders(res);

  const userData = await getUserData(req, res);
  const siteData = await getSiteData(req);

  try {
    const articles = await fetchArticles({
      offset: (Number(query.page || 1) - 1) * 10,
      limit: 10,
      sort: "newsmix",
      usermix: userData?.usermix,
      exchangeToken: userData?.exchangeToken
    });

    return {
      props: serializePropsResponse({
        articles,
        userData,
        siteData
      })
    };
  } catch (e) {
    const errorResponse = {
      articles: {
        articles: [],
        links: {},
        searchParams: {}
      }
    };
    if (e instanceof Error404) {
      return {
        props: {
          ...errorResponse,
          errorCode: 404
        }
      };
    }

    return {
      props: {
        ...errorResponse,
        errorCode: 500
      }
    };
  }
};

export default Main;
