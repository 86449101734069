import React from "react";

import type { ArticlesFeedProps } from "./BaseFeedView";
import type { LayoutType } from "../../../layout/Layout";
import type { Translation } from "../../../config/ConfigType";

import { NeedLogin } from "./NeedLogin";
import { BaseFeedView } from "./BaseFeedView";
import { LayoutWithoutSidebar } from "../../../layout/Layout";
import { getConfig } from "../../../config";
import TelescopeSVG from "../../../assets/svg/omni/telescope.svg";
import { login } from "../../../containers/GlobalStateContainer/userState/actions/login";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

const NoBookmarks = ({ translation }: { translation: Translation }) => (
  <LayoutWithoutSidebar withTopAd={false}>
    <div className="main-bg">
      <div className="bookmarks bookmarks--empty">
        {translation.bookmarks.image && (
          <TelescopeSVG className="bookmarks-emptyimg" />
        )}
        <p className="bookmarks-emptytext">{translation.bookmarks.title}</p>
        <p className="bookmarks-emptytext">{translation.bookmarks.text}</p>
      </div>
    </div>
  </LayoutWithoutSidebar>
);

export function BookmarksFeedView({
  feed,
  type
}: {
  feed: ArticlesFeedProps;
  type?: LayoutType;
}) {
  const { isLoggedIn } = useGlobalStore();
  const { translation } = getConfig();

  if (!isLoggedIn) {
    return <NeedLogin login={login} />;
  }

  if (!feed?.articles?.articles.length) {
    return <NoBookmarks translation={translation} />;
  }

  return <BaseFeedView type={type} feed={feed} />;
}
