import React from "react";

import type { LayoutType } from "../../../layout/Layout";

import { Layout } from "../../../layout/Layout";
import { FeedContainer } from "../../../containers/FeedContainer";
import { Feed } from "../../../components/Feed";

export type ArticlesFeedProps = Omit<
  React.ComponentProps<typeof FeedContainer>,
  "children"
>;

export function BaseFeedView({
  feed,
  type
}: {
  feed: ArticlesFeedProps;
  type?: LayoutType;
}) {
  return (
    <Layout type={type}>
      <FeedContainer {...feed}>
        <Feed type={type} />
      </FeedContainer>
    </Layout>
  );
}
