import React from "react";

import { LayoutWithoutSidebar } from "../../../layout/Layout";

export const NeedLogin = ({ login }: { login: () => void }) => (
  <LayoutWithoutSidebar withTopAd={false}>
    <div className="main-bg">
      <p>
        Du behöver{" "}
        <span>
          <button className="btn btn--medium btn--primary" onClick={login}>
            logga in
          </button>
        </span>{" "}
        för att se dina bokmärken.
      </p>
    </div>
  </LayoutWithoutSidebar>
);
